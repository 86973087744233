@use "sass:math";
@import '../../../config/variables';
@import '../../../config/mixins';

.notification {
  animation-name: show;
  animation-duration: $transition-time;
  border-radius: $main-spacing;
  cursor: pointer;

  padding: 0;
  z-index: 1002;

  .alert {
    margin: 0;
    border-radius: 0;
    font-weight: 300;
  }

  @include gt-sm {
    padding: math.div($main-spacing, 2);
  }
}

@keyframes show {
  0% {
    opacity: 0;
    margin-top: -$main-spacing;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
