@import './config/reset.scss';
@import './config/variables.scss';
@import './config/fonts.scss';
@import './config/typography.scss';
.file-wrapper {
  border: 2px dashed $color-inactive;
  padding: $main-spacing * 4;
  text-align: center;
  cursor: pointer;
  transition: $transition-time;
  border-radius: $border-radius * 2;
  &:hover {
    background-color: darken($color-background-secondary, 10%);
  }

  input {
    display: none;
  }

  .img-preview {
    max-width: 300px;
    max-height: 300px;
  }
}

html {
  font-size: 62.5%;
  font-family: 'blocal', sans-serif;
}

@supports (padding-top: constant(safe-area-inset-top)) {
  html {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
    overflow: hidden;
    padding: var(--safe-area-inset-top) var(--safe-area-inset-right) var(--safe-area-inset-bottom)
      var(--safe-area-inset-left);
    height: 100%;
  }
  body {
    height: 100%;
    overflow: auto;
    padding-bottom: constant(safe-area-inset-bottom);
  }
}
// Browsers which fully support CSS Environment variables (iOS 11.2+).
@supports (padding-top: env(safe-area-inset-top)) {
  html {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
    overflow: hidden;
    height: 100%;
    padding: var(--safe-area-inset-top) var(--safe-area-inset-right) var(--safe-area-inset-bottom)
    var(--safe-area-inset-left);
  }
  body {
    height: 100%;
    overflow: auto;
    padding-bottom: env(safe-area-inset-bottom);
  }
}

#root {
  position: relative;
}

.flatpickr-monthDropdown-months {
  display: inline;
}

.ce-inline-tool:last-child {
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
  &.ce-inline-tool--link {
    background-image: url('/assets/images/link.svg')
  }

  &.ce-inline-tool--unlink {
    background-image: url('/assets/images/unlink.svg')
  }
}