@use "sass:math";
@import "../../../config/variables.scss";

.update-info-bar {
  background-color: $color-primary;
  padding: $main-spacing * 2;
  display: block;
  cursor: pointer;
  font-size: 1.3rem;
  transition: $transition-time background-color;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
  box-shadow: 0 0 math.div($main-spacing, 2) math.div($main-spacing, 4) rgba(0, 0, 0, 0.5);

  &:hover {
    background-color: lighten($color-primary, 10%);
  }

  .update-info-icon {
    margin-right: math.div($main-spacing, 4);
  }
}
