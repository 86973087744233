@import "../../../config/variables";
@import "../../../config/mixins";

.notification-container {
  position: fixed;
  top: var(--safe-area-inset-top);
  right: 0;
  z-index: 999;
  width: 100%;



  @include gt-sm {
    width: 350px;
  }

  &.static {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
  }
}
