@use "sass:math";
@import "./variables.scss";

@mixin lt-sm {
  @media screen and (max-width: map-get($breakpoints, sm) - 1) {
    @content;
  }
}

@mixin lt-md {
  @media screen and (max-width: map-get($breakpoints, md) - 1) {
    @content;
  }
}

@mixin gt-xs {
  @media screen and (min-width: map-get($breakpoints, xs)) {
    @content;
  }
}

@mixin gt-sm {
  @media screen and (min-width: map-get($breakpoints, sm)) {
    @content;
  }
}

@mixin gt-md {
  @media screen and (min-width: map-get($breakpoints, md)) {
    @content;
  }
}

@mixin gt-lg {
  @media screen and (min-width: map-get($breakpoints, lg)) {
    @content;
  }
}

@mixin form-input {
  position: relative;
  color: $color-text;
  border: none;
  background-color: $color-background;
  padding: $main-spacing * 1.5 $main-spacing * 1.5;
  font-size: 1.4rem;
  font-family: $font-primary;
  font-weight: 400;
  width: 100%;
  outline: none;
  transition: $transition-time;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: $color-text !important;
    -webkit-box-shadow: 0 0 0px 1000px $color-primary inset !important;
    box-shadow: 0 0 0px 1000px $color-label inset !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }

  &::placeholder {
    color: $color-label;
    font-weight: 400;
  }

  &:focus {
    box-shadow: 0 math.div($main-spacing, 2) $main-spacing * 2 $main-spacing * (-1) rgba($color-primary, 0.5);
  }

  &.is-invalid {
    border: 1px solid $color-danger;
    box-shadow: 0 math.div($main-spacing, 2) $main-spacing * 2 $main-spacing * (-1) rgba($color-danger, 0.5);
    background-color: lighten($color-danger, 50%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.input-big {
    text-align: center;
    font-size: 1.5rem;
    padding: $main-spacing;
  }
}

@mixin bottom-part-with-radius {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  background-color: $color-background-secondary;
  box-shadow: 0px -8px 11px -5px #dedede;
  position: relative;
  top: -10px;
  padding: $main-spacing * 4 0;
  z-index: 99;
}