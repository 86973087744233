@use "sass:math";
@import "../../../config/mixins";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1002;
  animation-name: showModal;
  animation-duration: 0.15s;
  transition: opacity math.div($transition-time, 2) ease-in-out;
  opacity: 0;

  &.visible {
    opacity: 1;

    .modal-body {
      transform: scale(1);
    }
  }

  .modal-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .modal-body {
    position: relative;
    z-index: 1001;
    overflow: auto;
    height: 90vh;
    max-width: 90%;
    width: 100%;
    position: relative;
    transform: scale(0.9);
    transition: transform math.div($transition-time, 2) ease-in-out;

    p {
      max-width: 500px;
      font-size: 1.4rem;
    }
  }

  &.full-screen {
    .modal-body {
      max-width: 100%;
      width: 100%;
      height: 100%;
    }
  }
}

@include gt-sm {
  .modal {
    .modal-body {
      width: auto;
    }
  }
}
