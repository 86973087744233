@use "sass:math";
@import '../../../config/variables.scss';

.table-wrapper {
  .api-table {
    width: 100%;
    overflow: hidden;
  }
  .table-container {
    overflow-y: scroll;
    width: 100%;
  }
  .table {
    width: 100%;
    overflow: auto;
  }
  th {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    padding: 0 (math.div($main-spacing, 1.5)) (math.div($main-spacing, 1.5));
    vertical-align: middle;
    // &:first-child {
    //   padding-left: 0;
    // }

    .table-header-content {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      vertical-align: middle;
    }

    .sort-up {
      margin-left: 4px;
    }
  }
  tbody {
    tr {
      border-radius: $border-radius;
      height: 50px;
      &:nth-child(odd) {
        background: darken($color-background-secondary, 3%);
      }
      &.highlight {
        background: rgba($color-primary, 0.5);
      }

      &.dehighlight {
        opacity: 0.5;
        background: #fff;
      }
    }
  }
  td {
    padding: math.div($main-spacing, 1.5);
    font-size: 1.2rem;
    vertical-align: middle;
    text-align: center;
  }

  .search-fields {
    display: flex;
    flex-wrap: wrap;
  }
  .search-bar {
    .search-input {
      outline: none;
    }
  }
}
.table-wrapper {
  .list-element {
    margin-bottom: $main-spacing * 3;
    padding: $main-spacing;
    &:nth-child(odd) {
      background: darken($color-background-secondary, 3%);
    }
  }
  .list-row {
    .list-value {
      display: block;
      margin-bottom: $main-spacing;
      font-size: 1.2rem;
    }
    strong {
      font-weight: 600;
      display: block;
      margin: 0 0 $main-spacing 0;
      font-size: 1.3rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.mobile .list-buttons {
      display: flex;
      justify-content: space-between;
    }
  }
  .button {
    margin-right: 0.5 * $main-spacing;
  }
}

@media (min-width: map-get($breakpoints, md)) {
  .table-wrapper .search-bar {
    td .mobile {
      display: flex;
    }
  }
}
