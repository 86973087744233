@use "sass:math";
@import '../../../../config/variables.scss';
@import '../../../../config/mixins.scss';

.modal-content {
  position: relative;
  z-index: 1;
  padding: $main-spacing;
  transition: $transition-time;
  overflow: auto;
  box-shadow: 0 0 $main-spacing * 1.5 math.div($main-spacing, -2) rgba(0, 0, 0, 0.5);
  border-radius: math.div($main-spacing, 5);
  background-color: $color-background;
  width: 100%;
  margin: auto;
  &.light {
    background-color: $color-background-secondary;
  }
  @include gt-sm {
    min-width: 500px;
    width: 500px;

    &.wide {
      min-width: 750px;
      width: 750px;
    }
  }

  &.full-screen {
    width: auto;
  }
}
