@use "sass:math";
@import "../../config/variables.scss";

.app-status {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999999;
  background-color: $color-background-secondary;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: $main-spacing;

  .app-status-title {
    font-size: 1.8rem;
    margin-bottom: $main-spacing;
    font-weight: 700;
  }
}

.nowifi-icon {
  fill: $color-text;
  width: 80px;
  height: 80px;
  margin: $main-spacing * 2 0;
}
